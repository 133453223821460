<template>
  <div class="event-card">
    <div class="header">
      <h4>{{ event.name }}</h4>
      <p>{{ eventDate }}</p>
    </div>
    <div class="sub-header">
      <p>
        {{
          `${event.rounds.length} ${
            event.rounds.length == 1 ? "Round" : "Rounds"
          }`
        }}
        <template v-if="event.rounds[0].pools.length > 1">
          ({{ event.rounds[0].pools.length }} pools)
        </template>
      </p>
    </div>
    <div class="info">
      <div class="event-status" v-if="event.status == 'COMPLETED'">
        <On color="midnight" />
        <p>Finished</p>
      </div>
      <div class="event-status" v-else-if="showRegOpeningDate">
        <RegistrationOpen color="sunrise" />
        <p>
          Opens
          {{ regOpenDateFormater(event.registrationOpenAt) }}
        </p>
      </div>
      <template v-else>
        <div class="event-status">
          <template v-if="event.isRegistrationOpen">
            <RegistrationOpen color="grass" />
            <p>Open</p>
          </template>
          <template v-else>
            <Off />
            <p>Closed</p>
          </template>
        </div>
        <div class="confirmed">
          <ConfirmedPlayers />
          <p>{{ confirmedPlayersLength }}</p>
        </div>
        <div class="waiting">
          <WaitingList />
          {{ waitingListLength }}
        </div>
      </template>

      <div class="card-label ocean" v-if="event.pdgaType">
        {{ event.pdgaType.name }}
      </div>
    </div>
    <div
      class="view-event"
      @click="
        $router.push({ name: 'event-overview', params: { eventId: event.id } })
      "
    >
      View event
    </div>
  </div>
</template>

<script>
import moment from "moment";
import WaitingList from "@/assets/icons/WaitingList";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";

export default {
  name: "OrganizerTourEventCard",
  props: ["event"],
  data() {
    return {
      expanded: false,
    };
  },
  components: {
    RegistrationOpen,
    WaitingList,
    ConfirmedPlayers,
    Off,
    On,
  },
  computed: {
    showRegOpeningDate() {
      if (this.event.isRegistrationOpen) return false;
      if (moment(this.event.registrationOpenAt).isAfter()) return true;
      return false;
    },
    eventDate() {
      return moment(this.event.startDate).format("DD MMM");
    },
    confirmedPlayersLength() {
      return this.event.registrations.filter(
        (registration) => registration.status == "ACCEPTED"
      ).length;
    },
    waitingListLength() {
      return this.event.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;
    },
  },
  methods: {
    regOpenDateFormater(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
    eventDateFormater(date) {
      return moment(date).format("D MMM");
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.event-card {
  border-radius: 8px;
  border: 1px solid $sleet;
  background: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  transition: all ease 0.3s;

  .header {
    padding: 18px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    svg {
      transition: all ease 0.3s;
      &:hover {
        cursor: pointer;
        transform: rotate(90deg);
      }
    }

    h4 {
      margin: 0;
      font-size: 18px;
      max-width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    p {
      margin: 0;
      line-height: unset;
      font-size: 14px;
    }
  }

  .sub-header {
    margin: 12px 0 16px 0;
    padding: 0 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .card-label {
    margin-left: auto;
  }

  .view-event {
    height: 48px;
    margin: 24px 20px 22px 20px;
    background: $mist;
    border-radius: 6px;
    font-size: 16px;
    @include Gilroy-Bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      background: $fog;
    }
  }

  .confirmed {
    margin-left: 16px;
  }
  .waiting {
    margin-left: 16px;
  }

  .info {
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: flex-start;
    p {
      margin: 0;
      font-size: 14px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
    .confirmed,
    .waiting {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }

      svg {
        margin-right: 8px;
      }
    }
    .event-status {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .event-card {
    .header {
      align-items: center;
      padding: 20px 24px 0 24px;
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    .sub-header {
      margin: 14px 0 20px 0;
      p {
        font-size: 16px;
      }
    }
    .info {
      p {
        font-size: 16px;
      }
    }
    .event-tag {
      padding: 3px 5px;
    }
    .view-event {
      margin: 24px 24px 26px 24px;
    }
  }
}
</style>
