<template>
  <div v-if="loaded" class="events-lists">
    <div class="event-list" v-if="upcomingEvents.length > 0">
      <h6 class="light">Upcoming events</h6>
      <OrganizerTourEventCard
        :event="event"
        v-for="event in upcomingEvents"
        :key="event.id"
      />
    </div>

    <div class="event-list" :class="{ finished: upcomingEvents.length > 0 }">
      <div
        class="event-list-header"
        @click="finishedExpanded = !finishedExpanded"
      >
        <h6 class="light" v-if="upcomingEvents.length > 0">
          Finished events<span
            ><Chevron2 :direction="finishedExpanded ? 'up' : 'down'"
          /></span>
        </h6>
      </div>
      <slide-up-down
        :active="finishedExpanded || upcomingEvents.length == 0"
        :duration="250"
        class="slide-wrapper"
      >
        <OrganizerTourEventCard
          :event="event"
          v-for="event in finishedEvents"
          :key="event.id"
        />
      </slide-up-down>
    </div>
  </div>
</template>

<script>
import OrganizerTourEventCard from "@/components/OrganizerTourEventCard";
import SlideUpDown from "vue-slide-up-down";
import Chevron2 from "@/assets/icons/Chevron2";

export default {
  name: "ManagerTourEvents",
  components: {
    SlideUpDown,
    OrganizerTourEventCard,
    Chevron2,
  },
  data() {
    return {
      loaded: false,
      tourData: null,
      finishedExpanded: false,
    };
  },
  computed: {
    finishedEvents() {
      return this.tourData.events.filter(
        (event) => event.status == "COMPLETED"
      );
    },
    upcomingEvents() {
      return this.tourData.events.filter(
        (event) => event.status != "COMPLETED"
      );
    },
  },
  methods: {
    async loadData() {
      try {
        let tourData = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
            {
              tour(tourId:"${this.$route.params.tourId}"){
                id
                name
                type{
                  name
                  type
                }
                isPublished
                isArchived
                status
                eventsCount
                nextEvent{
                  startDate
                }
                events{
                  id
                  name
                  isRegistrationOpen
                  registrationOpenAt
                  status
                  startDate
                  pdgaType{
                    name
                    type
                  }
                  registrations{
                    id
                    status
                  }
                  rounds{
                    id
                    pools{
                      id
                    }
                  }
                }
              }
            }
          `,
          },
        });

        this.tourData = tourData.data.data.tour;
        this.loaded = true;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.events-lists {
  width: 100%;
  padding: 0 12px;
}
.event-list {
  &.finished {
    margin-top: 32px;
    h6 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
    .event-list-header {
      margin-bottom: 24px;
    }
  }

  h6 {
    width: 100%;
    font-size: 18px;
    margin-bottom: 24px;
    @include Gilroy-Regular;
  }
  .event-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .events-lists {
    padding: 0;
  }
  .event-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0;

    h6 {
      width: 100%;
      font-size: 18px;
      margin-bottom: 32px;
      @include Gilroy-Regular;
    }
    span {
      margin-left: 10px;
    }
    &.finished {
      .event-list-header {
        justify-content: flex-start;
        cursor: pointer;
      }
    }

    .event-card {
      width: calc(33.3% - 30px);
      margin-bottom: 32px;
      margin: 0 45px 32px 0;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    .event-list-header {
      width: 100%;
    }

    .slide-wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}
</style>
